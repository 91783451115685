import React from "react";
import Draggable from "react-draggable";

export default function StickyNote({ id, content, top, left, isNew }) {
  return (
    <Draggable defaultPosition={{ x: left, y: top }}>
      <div
        className="note"
        style={{
          transform: `rotate(${Math.random() * 20 - 10}deg)`,
          backgroundColor: isNew ? "yellow" : "#EBF6F2",
          boxShadow: isNew
            ? "0px 10px 20px rgba(0, 0, 0, 0.3)"
            : "5px 5px 15px rgba(0, 0, 0, 0.2)",
        }}
      >
        {content}
      </div>
    </Draggable>
  )
}
