import React, { useState, useEffect } from "react";
import "./App.css"; // Your CSS file (you can add it in src/App.css)
import StickyNote from "./components/StickyNote";
import {
  Button,
  Modal,
  Box,
  Typography,
  Container,
  IconButton,
  Grid2,
  TextField,
} from "@mui/material";
import { Add, Close } from "@mui/icons-material";
import ReCAPTCHA from "react-google-recaptcha";
const App = () => {
  const [notes, setNotes] = useState([]);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // For creating new note modal (opened manually)
  const [showIntroModal, setShowIntroModal] = useState(true); // For the modal that shows on load
  const [newNoteText, setNewNoteText] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);


useEffect(() => {
  fetch("https://player.tiftechnologies.com/api/player/1", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      access_alpha_num_key: process.env.REACT_APP_ACCESS_KEY, // Add token here
    },
  })
    .then((response) => response.json())
    .then((data) => {
      const notesWithPosition = data?.data?.rows.map((note) => ({
        ...note,
        top: Math.random() * (window.innerHeight - 150),
        left: Math.random() * (window.innerWidth - 150),
        isNew: false,
      }));
      setNotes(notesWithPosition);
    });
}, []);

// Modal open and close handlers
const openModal = () => {
  setShowModal(true);
  setShowIntroModal(false);
};
const closeModal = () => setShowModal(false);

// Save a new note
const saveNote = async () => {
  if (newNoteText.trim() === "") {
    setError("Note content cannot be empty!");
    return;
  }
  if (newNoteText.length > 150) {
    setError(`Note content cannot exceed ${150} characters!`);
    return;
  }
  if (!captchaValue) {
    setError("Please complete the CAPTCHA to submit the note.");
    return;
  }
  // Prepare the raw body for the API request
  const payload = {
    content: newNoteText, // Content from the textarea
  };

  try {
    // Make the POST request to the API
    const response = await fetch(
      "https://player.tiftechnologies.com/api/player/1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          access_alpha_num_key: process.env.REACT_APP_ACCESS_KEY,
        },
        body: JSON.stringify(payload), // Convert the payload to a JSON string
      }
    );

    // Check if the request was successful
    if (!response.ok) {
      throw new Error("Failed to save the note.");
    }

    const newNote = {
      id: Date.now(),
      content: newNoteText,
      top: Math.random() * (window.innerHeight - 200),
      left: Math.random() * (window.innerWidth - 200),
      isNew: true,
    };

    setNotes((prevNotes) => [...prevNotes, newNote]);
    setShowModal(false);
    setNewNoteText("");
    setCaptchaValue(null);
  } catch (error) {
    console.error("Error saving note:", error);
    alert("An error occurred while saving the note.");
  }
};

// Close the second modal (the one that shows on load)
const closeIntroModal = () => {
  setShowIntroModal(false);
};
const handleCaptchaChange = (value) => {
  setCaptchaValue(value);
};
return (
  <div className="board">
    <Container maxWidth="xl">
      <Grid2
        container
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        className="header"
      >
        <Grid2
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "flex-start" },
          }}
          size={{ xs: 12, sm: 5 }}
        >
          <img src="logo.png" alt="Logo" className="logo" />
        </Grid2>

        <Grid2
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", sm: "flex-end" },
          }}
          size={{ xs: 12, sm: 5 }}
        >
          <Button
            onClick={openModal}
            startIcon={<Add />}
            sx={{
              border: "1px solid #292929",
              borderRadius: "30px",
              color: "#000",
              fontWeight: "bold",
              bgcolor: "#fff",
            }}
          >
            Wish Goodluck to Gukesh
          </Button>
        </Grid2>
      </Grid2>

      <Grid2 container spacing={2} direction="column" alignItems="flex-start">
        {notes && notes.map((note) => <StickyNote key={note.id} {...note} />)}
      </Grid2>
    </Container>

    {/* Second Modal that shows on load */}
    <Modal open={showIntroModal} onClose={closeIntroModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          pt: 2,
          width: { xs: "90%", sm: "70%" },
          bgcolor: "#fff",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            mb: 2,
            pr: 2,
          }}
        >
          <IconButton onClick={closeIntroModal}>
            <Close />
          </IconButton>
        </Box>
        <Grid2
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid2 size={{ xs: 12, md: 6, lg: 5 }}>
            <Box
              sx={{
                position: "relative",
                height: "380px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: { xs: "-20%", sm: "0%", md: "-100px", lg: "-85px" },
                  // border: "2px solid green",
                }}
              >
                <img
                  src="/ellipse.png"
                  alt=""
                  title=""
                  className="ellipseImage "
                />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "-10px",
                  left: {
                    xs: "-35%",
                    sm: "-10%",
                    md: "-160px",
                    lg: "-180px",
                  },
                }}
                className="gukeshBorder"
              >
                <img
                  src="/gukesh.png"
                  alt=""
                  title=""
                  className="gukeshImage"
                />
              </Box>
            </Box>
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6, lg: 7 }} sx={{ px: { xs: 2, md: 5 } }}>
            <Typography
              textAlign="center"
              fontSize={{ xs: "15px", sm: "20px", md: "30px", lg: "35px" }}
              fontWeight="600"
            >
              Wish good luck to Gukesh for World Championship 2024
            </Typography>
            <Typography
              textAlign="center"
              fontSize={{ xs: "14px", sm: "15px", md: "17px", lg: "25px" }}
              fontWeight="light"
              sx={{ my: 2 }}
            >
              ✨ Gukesh, our chess prodigy, is making India proud on the global
              stage! Share your good luck wishes to cheer him on in his journey
              to greatness.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={openModal}
                startIcon={<Add />}
                sx={{
                  border: "1px solid #292929",
                  borderRadius: "30px",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                Wish Goodluck to Gukesh
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      </Box>
    </Modal>

    {/* Modal for creating a new note (sticky note) */}
    <Modal open={showModal} onClose={closeModal}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: { xs: 2, sm: 4 },
          width: { xs: "80%", sm: 400 },
          borderRadius: "9px",
        }}
      >
        <Typography fontSize={{ xs: "22px", sm: "28px" }} fontWeight="500">
          Wish good luck to Gukesh for World Championship 2024
        </Typography>
        <Box sx={{ my: 1 }}>
          <TextField
            multiline
            fullWidth
            value={newNoteText}
            onChange={(e) => setNewNoteText(e.target.value)}
            rows={4}
            placeholder="Write your message here..."
          />
        </Box>
        <Box style={{ marginTop: "16px", display: "flex" }}>
          <ReCAPTCHA
            sitekey={"6LcK3oYqAAAAACDFmuT15IAuSd_wK0Tr0xDAiph1"}
            onChange={handleCaptchaChange}
          />
        </Box>
        {error && (
          <Typography
            sx={{ color: "red", fontSize: "12px", textAlign: "center" }}
          >
            {error}
          </Typography>
        )}
        <Box
          // className="modal-actions"
          style={{ marginTop: "16px", display: "flex" }}
        >
          <Button
            variant="outlined"
            fullWidth
            onClick={saveNote}
            sx={{ marginRight: 2, bgcolor: "#163ebc", color: "#ddd" }}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={closeModal}
            sx={{ bgcolor: "#ddd", color: "#163ebc", border: "none" }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  </div>
);
};

export default App;
